<template>
  <div class="index">
    <div class="bg"></div>
    <div class="header-wrapper">
      <div class="header">
        <router-link :to="{ path: '/index' }" class="login">
          <img src="@/assets/img_01.png" alt="logo" />
        </router-link>
        <div class="header-menu-wrapper">
          <router-link
            :to="{ path: '/info' }"
            class="header-menu-item"
            active-class="active"
          >
            <img src="@/assets/img_02.png" alt="logo" />
            <p>患者信息</p>
          </router-link>
          <router-link
            :to="{ path: '/list' }"
            class="header-menu-item"
            active-class="active"
          >
            <img src="@/assets/img_03.png" alt="logo" />
            <p>医院信息</p>
          </router-link>
          <!-- <router-link
            :to="{ path: '/doctor' }"
            class="header-menu-item"
            active-class="active"
            v-if="info.user_cate == 2"
          >
            <img src="@/assets/img_03.png" alt="logo" />
            <p>医生信息</p>
          </router-link> -->
          <div class="user-wrapper">
            <el-dropdown @command="handleDropdown" placement="bottom-end">
              <div class="user-wrapper-1">
                <img src="@/assets/img_04.png" alt="" class="icon" />
                {{ info.organization }}
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item disabled>
                  {{ info.phone | nMobile }}</el-dropdown-item
                >
                <el-dropdown-item command="setPassword"
                  >修改密码</el-dropdown-item
                >
                <el-dropdown-item command="signOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="box-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      info: { phone: "" },
    };
  },
  created() {
    this.getInfo();
  },
  computed: {},
  methods: {
    handleDropdown(command) {
      if (command == "signOut") {
        // localStorage.removeItem("_token_");
        //删除cookie字段
        this.$cookie.delete("_token_");
        this.$router.push({
          path: "/login",
        });
      } else {
        this.$router.push({
          path: "/" + command,
        });
      }
    },
    getInfo() {
      this.$api.info().then((res) => {
        this.info = res.data;
        localStorage.setItem("_type_", res.data.user_cate);
      });
    },
  },
  filters: {
    nMobile(val) {
      let s = val + "";
      if (s) {
        return s.substring(0, 3) + "****" + s.substring(7, 11);
      }
      return s;
    },
  },
};
</script>
<style lang="scss">
.index {
  .user-wrapper-1 {
    display: flex;
    align-items: center;
    margin-left: 77px;
    font-size: 16px;
    color: #cde4d8;
    cursor: pointer;
    img.icon {
      margin-right: 12px;
    }
  }
}
.el-dropdown-menu__item {
  &:hover {
    color: #2d8cf0 !important;
  }
}
</style>

<style lang="scss" scoped>
.index {
  min-width: 1380px;
  height: 100vh;
  padding-top: 155px;
  .header-wrapper {
    background: #2d8cf0;
    margin-bottom: 55px;
    position: fixed;
    top: 0;
    z-index: 9;
    left: 0;
    right: 0;
    .header {
      width: 1380px;
      height: 100px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .login {
        img {
        }
      }

      .header-menu-wrapper {
        display: flex;
        align-items: center;
        .header-menu-item {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #cde4d8;
          margin-left: 32px;
          &.active {
            color: #fff;
          }
          img {
            margin-right: 6px;
          }

          p {
          }
        }

        .user-wrapper {
          display: flex;
          align-items: center;
          margin-left: 77px;
          font-size: 16px;
          color: #cde4d8;
          img.icon {
            margin-right: 12px;
          }
        }
      }
    }
  }
  .bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 100px;
    height: 237px;
    background: url(../assets/bg_02.png) no-repeat;
    background-size: cover;
  }
  .box-wrapper {
    width: 1380px;
    margin: 0 auto;
    // position: relative;
    // z-index: 1;
  }
}
</style>